<script>
// Import Vue framework for building the application
import Vue from "vue";

// Import layout and components for the page
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

// Import validators from Vuelidate for form validation
import { email, required } from "vuelidate/lib/validators";

// Import HTTP request handling module
import HttpRequest from "@/http/HttpRequest";
let httpRequest = new HttpRequest(); // Initialize an instance of HttpRequest

// Import and initialize loading overlay component
import Loading from "vue-loading-overlay";
Vue.use(Loading);

// Import Preference module for application settings
import Preference from "@/data/Preference";
let preference = new Preference(); // Initialize an instance of Preference

// Import styles for the loading overlay
import "vue-loading-overlay/dist/vue-loading.css";

// Import and initialize Role module for role-based access control
import Role from "@/data/Roles";
let roleChecker = new Role(); // Initialize an instance of Role

export default {
  data() {
    // Data properties for the Vue component
    return {
      isLoading: false, // Flag to indicate loading state
      submitted: false, // Flag to track form submission
      title: "Company", // Title for the page
      role: preference.getData(Preference.KEY_ROLE_ID), // User role retrieved from application preference
      access_property: "Company", // Access property for the page
      items: [
        // Breadcrumb items for navigation
        {
          text: "WORKANY",
          to: "/",
        },
        {
          text: "Workspace",
          active: true,
        },
      ],
      email: "", // User email
      error: null, // Error message placeholder
      work_space_id: "", // Workspace ID
      workspace_name: "", // Workspace name
      company_name: "", // Company name
      company_logo_url: "", // URL for company logo
      company_logo: "", // Placeholder for company logo
      company_email: "", // Company email address
      active_package: 1, // Active package ID
      org_domain: "", // Organization domain
    };
  },

  validations: {
    // Form validation rules using Vuelidate
    company_name: { required }, // Company name is required
    company_email: { required, email }, // Company email must be required and a valid email format
  },
  components: {
    // Registered components for the Vue component
    Layout, // Main layout component
    PageHeader, // Page header component
  },

  methods: {
    // Check if the current role has access to a specific resource and action
    isRoleAccess(role_id, resource, action) {
      return roleChecker.checkAccess(role_id, resource, action);
    },

    // Retrieve workspace details from the server
    async getWorkSpaceDetails() {
      let _this = this;
      _this.isLoading = true;
      return httpRequest.getWorkspaceDetails().then(function (response) {
        _this.isLoading = false;
        if (response.success) {
          // Update component properties with retrieved data
          _this.workspace_name = response.data.work_space_name;
          _this.company_name = response.data.company_name;
          _this.company_email = response.data.company_email;
          _this.active_package = response.data.subscription_id;
          _this.work_space_id = response.data.workspace_id;
          _this.org_domain = response.data.work_space;
        }
      });
    },

    // Handle file input change event to update company logo and URL
    async onChange(e) {
      const file = e.target.files[0];
      this.company_logo = file;
      this.company_logo_url = URL.createObjectURL(file);
    },

    // Update the active package for the workspace
    updateActivePackage(value) {
      this.active_package = value;
    },

    // Update workspace details on the server
    updateWorkSpace() {
      this.submitted = true;

      // Stop here if the form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        // Initialize loader
        let loader = this.$loading.show({
          container: this.fullPage ? null : this.$refs.formContainer,
          canCancel: false,
          onCancel: this.onCancel,
          backgroundColor: "#ffffff",
          loader: "dots",
        });

        // Initialize sweet alert
        let swal = this.$swal;

        // Send update request to the server
        httpRequest
          .updateWorkspaceDetails(
            this.work_space_id,
            this.company_name,
            this.company_email,
            this.company_logo,
            this.active_package
          )
          .then(function (response) {
            loader.hide();
            if (response.success) {
              swal(response.message);
            } else {
              swal(response.message);
            }
          });
      }
    },
  },

  // Lifecycle hook: Called when the Vue instance is created
  async created() {
    // Await the retrieval of workspace details before proceeding
    await this.getWorkSpaceDetails();
  },
};
</script>

<template>
  <!-- Main layout -->
  <Layout>
    <!-- Page header with dynamic title and breadcrumb items -->
    <PageHeader :title="title" :items="items" />

    <!-- Content area -->
    <div class="row">
      <div class="col-4 mb-4">
        <!-- Company details card -->
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Company</h4>

            <!-- Company details form -->
            <div class="p-2 w-100" style="max-width: 500px">
              <form class="form-horizontal" @submit.prevent="updateWorkSpace">
                <!-- Company Name -->
                <div class="form-group auth-form-group-custom mb-3 mb-md-4">
                  <i
                    class="ri-building-3-line auti-custom-input-icon customs-text-color"
                  ></i>
                  <label for="companyname">Company Name</label>
                  <input
                    type="text"
                    class="form-control"
                    id="companyname"
                    :readonly="!isRoleAccess(this.role, access_property, 'update')"
                    :style="{
                      'background-color': isRoleAccess(
                        this.role,
                        access_property,
                        'update'
                      )
                        ? 'white'
                        : '#eee',
                    }"
                    :class="{ 'is-invalid': submitted && $v.company_name.$error }"
                    placeholder="Enter name"
                    v-model="company_name"
                  />
                  <div
                    v-if="submitted && !$v.company_name.required"
                    class="invalid-feedback"
                  >
                    Company name is required.
                  </div>
                </div>

                <!-- Company Email -->
                <div class="form-group auth-form-group-custom mb-3 mb-md-4">
                  <i class="ri-mail-line auti-custom-input-icon customs-text-color"></i>
                  <label for="companyemail">Company Email</label>
                  <input
                    type="text"
                    class="form-control"
                    :readonly="!isRoleAccess(this.role, access_property, 'update')"
                    :style="{
                      'background-color': isRoleAccess(
                        this.role,
                        access_property,
                        'update'
                      )
                        ? 'white'
                        : '#eee',
                    }"
                    id="companyemail"
                    :class="{ 'is-invalid': submitted && $v.company_email.$error }"
                    placeholder="Enter email"
                    v-model="company_email"
                  />
                  <div
                    v-if="submitted && $v.company_email.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.company_email.required"
                      >Company email is required.</span
                    >
                    <span v-if="!$v.company_email.email"
                      >Please enter a valid email.</span
                    >
                  </div>
                </div>

                <!-- Workspace Name -->
                <div class="form-group auth-form-group-custom mb-3 mb-md-4">
                  <i
                    class="ri-building-2-line auti-custom-input-icon customs-text-color"
                  ></i>
                  <label for="workspacename">Workspace Name</label>
                  <input
                    type="text"
                    class="form-control"
                    id="workspacename"
                    readonly
                    style="background-color: #eee"
                    placeholder="Enter name"
                    v-model="workspace_name"
                  />
                </div>

                <!-- Workspace URL -->
                <div class="form-group auth-form-group-custom mb-3 mb-md-4">
                  <i
                    class="ri-building-2-line auti-custom-input-icon customs-text-color"
                  ></i>
                  <label for="workspaceurl">Workspace URL</label>
                  <input
                    type="text"
                    class="form-control"
                    id="workspaceurl"
                    readonly
                    style="background-color: #eee"
                    v-model="org_domain"
                  />
                </div>

                <!-- Company Logo -->
                <div class="form-group">
                  <b-row class="display-center">
                    <b-col cols="4">
                      <b-img
                        v-if="company_logo_url"
                        :src="company_logo_url"
                        height="80"
                        alt="Responsive image"
                      ></b-img>
                    </b-col>
                    <b-col
                      cols="8"
                      v-if="isRoleAccess(this.role, this.access_property, 'update')"
                    >
                      <b-form-file
                        id="file-large"
                        placeholder="Select Logo"
                        size="lg"
                        type="file"
                        accept=".jpg, .png"
                        @change="onChange"
                      ></b-form-file>
                    </b-col>
                  </b-row>
                </div>

                <!-- Update button -->
                <div
                  v-if="isRoleAccess(this.role, this.access_property, 'update')"
                  class="mt-10 mt-md-10 text-right"
                >
                  <b-button
                    class="customs-bg-color"
                    variant="primary"
                    size="lg"
                    type="submit"
                  >
                    UPDATE
                    <i class="ri-arrow-right-s-line" style="vertical-align: bottom"></i>
                  </b-button>
                </div>
              </form>

              <!-- Free trial information -->
              <div class="mt-5">
                <b-row class="display-center customs-text-color font-weight-bold">
                  <b-col cols="12">
                    <div class="text-center">YOU ARE USING FREE TRIAL</div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.selected_package {
  border: 1px solid #f2a503;
  color: #111;
  background-color: #eff2f7;
}

.display-center {
  display: flex;
  align-items: center;
}

.customs-bg-color {
  background-color: #ff7c47 !important;
  border: none;
}

.customs-text-color {
  color: #ff7c47;
}
</style>
